<template>
  <div
    :style="
      $route.matched[0].path.substring(1) === 'parent'
        ? ''
        : 'background-color: #ffeddd'
    "
    class="py-8"
  >
    <v-container>
      <v-row>
        <v-col
          cols="12"
          :sm="
            this.$route.matched[0].path.substring(1) === 'parent' ? '8' : '12'
          "
        >
          <v-row>
            <v-col
              ><div class="heading">
                Prizes Won by {{ studentDetail.student_first_name }}.
                <div class="hLine"></div></div
            ></v-col>
          </v-row>
          <v-row>
            <!--<div style="width: 12.5%" v-for="(item, i) in prizes" :key="i" class="pa-2">
              <img
                :src="`${item.logo}`"
                width="100%"
                alt=""
                :style="item.is_earned ? '' : 'filter: grayscale(90%);'"
              />
              <div class="prizeDes" v-if="!item.is_earned">
                {{ item.description }}
              </div>
            </div>-->
            <v-col
              v-for="(item, i) in prizes"
              :key="i"
              class="py-4 px-0"
           style="width:14% !important" >
              <div  class="d-flex justify-center">
              <img
                :src="`${item.logo}`"
                width="90%"
                alt=""
              />
               <!-- :style="item.is_earned ? '' : 'filter: grayscale(100%);'"-->
              </div>
              <div class="prizeDes d-flex justify-center text-center" v-if="!item.is_earned">
                {{ item.description }}
              </div>
            </v-col>
          </v-row>
         <!-- v-if="$vuetify.breakpoint.smAndUp"-->
          <v-row >
            <v-col cols="12">
              <div class="slidecontainer">
                <input
                  :style="sliderColor"
                  type="range"
                  min="0"
                  max="100"
                  :value="value"
                  class="slider"
                  id="myRange"
                  disabled
                />
              </div>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  name: "PrizesWon",
  data() {
    return {
      prizes: [],
      value: 0,
    };
  },
  computed: {
    ...mapGetters({
      getCampaign: "publicDonationPage/getCampaign",
    }),
    studentDetail() {
      return this.getCampaign.student_detail;
    },
    sliderColor() {
      if (this.value > 50) {
        return {
          background:
            "linear-gradient(to right, #83008B 0%,#E71953 35%, #FBB927 " +
            this.value +
            "%, #d3d3d3 " +
            this.value +
            "%,#d3d3d3 75%, #d3d3d3 100%)",
        };
      } else {
        return {
          background:
            "linear-gradient(to right, #83008B 0%, #FBB927 " +
            this.value +
            "%, #d3d3d3 " +
            this.value +
            "%, #d3d3d3 100%)",
        };
      }
    },
  },
  methods: {
    setSliderValue() {
      let count = 0;
      for (const obj of this.prizes) {
        // console.log(obj, "prize obj");
        if (obj.is_earned) count = count + 1;
      }
      // console.log(count, "count");
      switch (count) {
        case 1:
          this.value =10;
          // this.value = 85
          break;
        case 2:
          this.value = 27;
          // this.value = 100
          break;
        case 3:
          this.value = 42;
          break;
        case 4:
          this.value = 55;
          break;
        case 5:
          this.value = 72;
          break;
        case 6:
          this.value = 85;
          break;
            case 7:
          this.value = 100;
          break;
        // case 7:
        //   this.value = 85;
        //   break;
        // case 8:
        //   this.value = 100;
        //   break;
        default:
          this.value = 0;
          break;
      }
    },
  },
  mounted() {
    this.prizes = this.studentDetail.prize;
    this.setSliderValue();
  },
};
</script>
<style scoped>
.heading {
  font-size: 25px;
  font-family: Roboto Slab;
  font-weight: 600;
  color: #2c1963;
  display: flex;
}
.hLine {
  background-color: #2c1963;
  width: 50px;
  height: 4px;
  border-radius: 20px;
  margin-top: auto;
  margin-bottom: auto;
  margin-left: 15px;
}

/*range slider*/
.slidecontainer {
  width: 100%;
  height: 50px;
}

.slider {
  -webkit-appearance: none;
  width: 100%;
  height: 15px;
  border-radius: 20px;
  background: #d3d3d3;
  outline: none;
}

.slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 50px;
  height: 70px;
  border: 0;
  background: url("../../../assets/publicDonationPage/prizeSlider.svg");
  cursor: pointer;
  background-size: 50px, 70px;
  background-repeat: no-repeat;
}

.slider::-moz-range-thumb {
  -webkit-appearance: none;
  width: 70px;
  height: 24px;
  border: 0;
  background: url("../../../assets/publicDonationPage/prizeSlider.svg");
  cursor: pointer;
  
}
.prizeDes {
  font-size: 12px;
  color: #757575;
  align-items: center;
}
@media only screen and (max-width: 600px) {
  .prizeDes {
    font-size: 8px;
  }
  .heading {
    font-size: 20px;
  }
  .hLine {
    background-color: #2c1963;
    width: 30px;
    height: 3px;
  }
}
</style>
<style>
.slider::-moz-range-thumb {
 background-size: contain !important;
 background-repeat: no-repeat !important;
 height:20px !important;
}
</style>
